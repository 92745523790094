

import AbstractComponentVue from '../services/AbstractComponentVue';
import {Component} from 'vue-property-decorator';
import UserCard from '@/components/UserCard.vue';
import {IUserResponse} from '@/services/dto/IUserResponse';
import {IUserService} from '@/services/dto/IUserService';
import {services} from '@/main';
import AlertService from '@/utils/AlertService';
import {EnumUserServiceType} from '@/services/dto/IDtos';

@Component({
	components: {
		UserCard,
	},
})
export default class MailDeactivation extends AbstractComponentVue {
	private accountId: number = +this.$route.params.id;
	private mailDeactivationDelay: number = 0;
	private mailService: IUserService = {} as IUserService;
  private isLoading: boolean = true;

  private mounted() {
    services.restService.callGet('/api/v1/user-services/mail/removal-delay')
        .then((res) => {
          this.mailDeactivationDelay = res.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    this.loadService(this.accountId);
  }

	private loadService(id: number): void {
		services.restService.callGet('/api/v1/accounts/' + id)
			.then((res) => {
				const userResponse: IUserResponse = res.data;
				this.mailService = {} as IUserService;
				userResponse.userServices.forEach(
					(x) => {
            if (x.serviceType === EnumUserServiceType.MAIL) {
              this.mailService = x;
            }
				});
		});
	}

	/**
	 * Annule la désactivation du mail.
	 */
	private cancel(): void {
		services.routageService.goTo('/account/' + this.accountId + '/services');
	}

	/**
	 * Désactive le service mail.
	 */
  private deactivateMail(): void {
    this.showModal('modal-traitement-cours');
    services.restService.callDelete('/api/v1/user-services/mail/' + this.accountId)
        .then((res) => {
          if (res !== undefined && res.request.status === 200) {
            AlertService.success('Votre demande a bien été prise en compte.');
            services.routageService.goTo('/account/' + this.accountId + '/services');
            services.loginService.ifCurrentUserReloadStore(this.accountId,
                () => this.hideModal('modal-traitement-cours'));
          } else {
            this.hideModal('modal-traitement-cours');
          }
        })
        .catch((resp) => {
          if (resp.request.status === 404) {
            // on affiche que l'utilisateur n'a pas été trouvé.
            AlertService.warning('L\'utilisateur concerné n\'a pas été trouvé.');
          } else if (resp.request.status === 403) {
            // Accès interdit
            AlertService.warning('Accès interdit. Vous ne disposez pas des autorisations nécessaires.');
          }
          this.hideModal('modal-traitement-cours');
        });
  }
}
